import React from "react";

const TermsGeneral = () => {
  return (
    <div>
      <div className="container termsPrivacy-container">
        <div className="title">서비스이용약관</div>

        <ul className="outer_list">
          <li className="section">
            <div className="section-title">제1조 목적</div>
            <ul className="inner_list decimal-leading-zero ">
              <li>
                국민대부㈜(이하 "당사")이 운영하는 유료서비스인
                멤버십서비스(이하 "서비스")는 본 "서비스" 이용약관(이하
                "약관")에 동의하여 "당사"의 웹사이트 krdriver.com(이하
                "웹사이트")의 회원으로 등록된 가입자(이하 "회원"에 한하여 이용할
                수 있는 서비스입니다.
              </li>
              <li>
                "약관"은 "서비스"를 이용하는 모든 "회원"의 "서비스" 사용 전반에
                관하여 필요한 내용을 규정하는 것을 목적으로 합니다.
              </li>
            </ul>
          </li>

          <li className="section">
            <div className="section-title">제2조 약관의 효력 및 변경</div>
            <ul className="inner_list decimal-leading-zero ">
              <li>
                본 "약관"은 그 내용을 "당사"의 "서비스" 화면에 게시하는 등의
                방법으로 "회원"에게 공시하거나 통보함으로써 효력이 발생합니다.
              </li>
              <li>
                "당사"는 합리적인 사유가 발생되는 경우 이 "약관"을 변경할 수
                있으며, "약관"이 변경된 경우에는 전 1항과 같은 방법으로 효력을
                발생시킵니다.{" "}
              </li>
              <li>
                "회원"이 변경된 "약관"에 동의하지 아니하는 경우 "회원"은 본인의
                회원등록을 취소할 수 있으며, 계속 사용의 경우는 "약관" 변경에
                대한 동의로 간주됩니다.
              </li>
            </ul>
          </li>

          <li className="section">
            <div className="section-title">제3조 이용계약의 성립</div>
            <ul className="inner_list decimal-leading-zero ">
              <li>
                "회원"이 변경된 "약관"에 동의하지 아니하는 경우 "회원"은 본인의
                회원등록을 취소할 수 있으며, 계속 사용의 경우는 "약관" 변경에
                대한 동의로 간주됩니다.
              </li>
              <li>
                가입신청 양식에 기재하는 모든 "회원" 정보는 모두 실제 데이터인
                것으로 간주됩니다.실명이나 실제 정보를 입력하지 않은 "회원"은
                법적인 보호를 받을 수 없으며, 서비스 이용에 제한을 받을 수
                있습니다.
              </li>
              <li>
                "회원"은 자신의 아이디(ID)와 비밀번호를 관리할 의무를 가지며,
                "회원"의 아이디와 비밀번호를 사용하여 발생하는 모든 결과에 대한
                책임은 전적으로 "회원"에게 있습니다.
              </li>
            </ul>
          </li>

          <li className="section">
            <div className="section-title">제4조 서비스 이용료 및 결제방식</div>
            <ul className="inner_list decimal-leading-zero ">
              <li>
                당사의 "서비스"는 일정 금액을 "당사"가 제공하는 결제 수단을 통해
                "회원"이 선입금한 후 이용할 수 있습니다.
              </li>
              <li>
                당사의 "서비스" 이용료 결제는 3개월, 12개월, 36개월 일단위
                "서비스" 결제는 지원하지 않습니다.
              </li>
            </ul>
          </li>

          <li className="section">
            <div className="section-title">제5조 서비스 계약기간의 연장</div>
            <p>회원은 서비스 이용료 결제시 계약기간을 연장할수 있습니다.</p>
          </li>

          <li className="section">
            <div className="section-title">제6조 서비스 중도해지 및 환불</div>
            <ul className="inner_list decimal-leading-zero ">
              <li>
                "회원"이 계약 기간 종료 이전에 계약을 중도에 해지하고자 하는
                경우에는 "회원" 본인이 이메일 혹은 유선을 통해 중도해지를
                신청해야 합니다.
              </li>
              <li>
                계약 중도 해지를 통한 결제금액의 환불은 일할 계산하여 잔여일수에
                상당하는 금액에서 각종 취급 수수료를 공제한 잔액을 환불 합니다.
                단, 카드 가맹 수수료 및 송금 수수료 등 각종 취급 수수료는
                발생시에 한해 공제합니다.
              </li>
              <li>
                회원이 당사의 웹사이트운영에 피해를 끼쳤다고 판단되는 경우,
                당사는 일방적으로 계약을 해지할수 있습니다.
              </li>
              <li>본 약관에서 정의하지 않은 사항은 환불정책을 따릅니다.</li>
            </ul>
          </li>

          <li className="section">
            <div className="section-title">제7조 양도금지</div>
            <p>
              "회원"의 "약관" 상의 권리는 이를 양도하거나 증여할 수 없으며,
              질권의 목적으로 사용할 수 없습니다.
            </p>
          </li>

          <li className="section">
            <div className="section-title">제8조 서비스의 중지</div>
            <ul className="inner_list decimal-leading-zero ">
              <li>
                "당사"가 정상적인 "서비스" 제공의 어려움으로 인하여 일시적으로
                "서비스"를 중지하여야 할 경우에는 "서비스" 중지 1주일 전의 고지
                후 "서비스"를 중지할 수 있으며 이 기간 동안 "회원"이 고지 내용을
                인지하지 못한데 대하여 "당사"는 책임을 부담하지 아니합니다.
                불가피한 사정이 있을 경우 위 사전 고지기간은 단축되거나 생략될
                수 있습니다."당사"의 사정으로 "서비스"를 중단하여야 할 경우 전
                1항을 준용합니다. 다만, 이 경우 사전 고지기간은 1개월로
                합니다."당사"는 "제 7조 양도금지" 항목의 내용에 위반하는 행동을
                한 "회원"에 대하여 임의로 "서비스" 사용중지 조치를 취할 수
                있습니다. 이 경우 "당사"는 해당 "회원"의 접속을 금지할 수
                있으며, 해당 "회원"이 게시한 내용의 전부 또는 일부를 임의로
                삭제할 수 있습니다.
              </li>
            </ul>
          </li>

          <li className="section">
            <div className="section-title">제9조 광고게재</div>
            <p>
              "당사"는 "서비스"의 운영과 관련하여 "서비스" 화면 등에 광고를
              게재할 수 있으며 "회원"은 이에 동의하는 것으로 간주합니다.
            </p>
          </li>

          <li className="section">
            <div className="section-title">제10조 면책</div>
            <ul className="inner_list decimal-leading-zero ">
              <li>
                "당사"는 본 "약관"에서 달리 정한 것을 제외하고는 "서비스"에
                게재된 어떠한 의견이나 정보에 대하여도 대표성을 가지지 않으며,
                "서비스" 회원이나 제3자가 표출한 의견을 승인, 반대 및 수정하지
                않습니다.
              </li>
              <li>
                "회원"은 "서비스"에 게재된 정보를 스스로의 판단 하에서 자유롭게
                이용할 권리가 있으며, "당사"는 어떠한 경우라도 "회원"이
                "서비스"에 게재된 정보에 의해 입은 손해나 피해에 대해 책임이
                없습니다.
              </li>
              <li>
                "서비스"의 효율적 제공을 위한 시스템 개선 공사, 장비증설 및
                상향공사 등 계획공사의 사유로 "회원"에게 사전 통보한 경우에는
                책임을 면합니다.
              </li>
              <li>
                "당사"는 천재지변 또는 이에 준하는 불가항력으로 인하여
                "서비스"를 제공할 수 없는 경우에는 "서비스" 제공에 관한 책임이
                면제됩니다.
              </li>
              <li>
                "당사"는 회원의 귀책사유로 인하여 "서비스" 이용의 장애가 발생한
                경우에는 책임이 면제됩니다.
              </li>
              <li>
                서비스와 관련하여 게재한 정보나 자료 등의 신뢰성, 정확성 등에
                대하여 당사는 고의 또는 중대한 과실이 없는 한 책임을 지지
                않습니다.
              </li>
              <li>
                당사는 무료로 제공되는 서비스 이용과 관련하여 회원에게 발생한
                손해에 대해서는 책임을 지지 않습니다.
              </li>
              <li>
                당사는 회원이 본인의 이메일id와 비밀번호를 관리하지 않아 발생한
                손해에 대하여 책임을 면합니다.
              </li>
            </ul>
          </li>

          <li className="section">
            <div className="section-title">제11조 개인정보의 보호 및 사용 </div>
            <ul className="inner_list decimal-leading-zero">
              <li>
                당사는 이용자의 정보 수집시 원활한 서비스 제공을 위해 필요한
                최소한의 정보를 수집하며, 당사는 관련 법령에 의하거나, 관련
                국가기관 등의 요청이 있는 경우를 제외하고는 회원의 개인정보를
                본인의 동의 없이 타인에게 제공하지 않습니다.
              </li>
              <li>
                본 조항에서 정의하지 않은 사항은 개인정보처리방침을 따릅니다.
              </li>
            </ul>
          </li>

          <li className="section">
            <div className="section-title"> 제12조 회원의 의무</div>
            <p>
              회원은 당사에서 제공하는 서비스의 이용과 관련하여 다음 각 호에
              해당하는 행위를 해서는 안됩니다.
            </p>
            <ul className="inner_list decimal-leading-zero">
              <li>
                당사의 직원이나 운영자를 가장하거나 타인의 명의를 도용하여
                메일을 발송하는 행위
              </li>
              <li>타인으로 가장하거나 타인과의 관계를 허위로 명시하는 행위</li>
              <li>
                다른 회원의 개인정보를 무단으로 수집, 저장, 게시 또는 유포하는
                행위
              </li>
              <li>
                서비스를 무단으로 영리, 영업, 광고, 홍보, 정치활동, 선거운동 등
                본래의 용도 이외의 용도로 이용하는 행위
              </li>
              <li>
                당사의 서비스를 이용하여 얻은 정보를 무단으로 복제, 유통,
                조장하거나 상업적으로 이용하는 행위
              </li>
              <li>
                그 밖에 법령에 위반되거나 선량한 풍속 기타 사회통념에 반하는
                행위
              </li>
            </ul>
          </li>

          <li className="section">
            <div className="section-title">제13조 (회원에 대한 통지)</div>
            <ul
              className="inner
            _list circle_list"
            >
              <li>
                “당사”가 회원에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는
                한 회원 가입 시 제공한 전자우편주소 또는 전화 문자 메시지 등으로
                할 수 있습니다.
              </li>
              <li>
                “당사”는 회원 전체에 대한 통지의 경우 7일 이상 사이트 화면에
                게시함으로써 제1항의 통지에 갈음할 수 있습니다.
              </li>
            </ul>
          </li>
          <li className="section">
            <div className="section-title">제14조 (재판권 및 준거법)</div>
            <p>
              본 약관에 정의하지 않은 사항은 사회통념상 해석에 따르거나 판례를
              따릅니다. 당사와 회원간에 분쟁이 발생하는 경우 관할법원은
              “서울서부지방법원”으로 합니다.
            </p>
            <p>
              <strong>[부칙]</strong>
              <br />
              (시행일) 이 약관은 2024년 9월1일부터 시행합니다.
            </p>
          </li>
        </ul>
      </div>

      <style jsx>{`
        .termsPrivacy-container {
          width: 80%;
          margin: 0 auto;
          padding: 80px 0;

          .title {
            font-size: 30px;
            font-weight: bold;
            color: #555;
            padding: 0;
            margin-bottom: 30px;
          }
          .title_sub {
            font-size: 14px;
            margin-top: 20px;
          }
          .outer_list {
            li {
              font-size: 14px;
              line-height: 20px;
            }
            > li {
              font-weight: bold;
              .inner_list {
                padding-left: 15px;
                li {
                  font-weight: normal;
                  margin-bottom: 10px;
                }
              }

              .decimal-leading-zero {
                counter-reset: list-item;
                list-style-type: decimal;
                li {
                  counter-increment: list-item; /* 각 항목마다 카운터 증가 */
                  position: relative;
                  margin-left: 8px;
                }
                li::marker {
                  content: "(" counter(list-item) ") "; /* 숫자를 괄호로 감싸기 */
                }
              }
              .circle_list {
                counter-reset: list-item;
                list-style-type: none;
                > li {
                  counter-increment: list-item;
                  list-style-type: none; /* 기본 숫자 제거 */
                  margin-left: 25px;
                  position: relative;
                  font-weight: normal;
                  &::before {
                    content: counter(list-item);
                    position: absolute;
                    left: -20px;
                    top: 4px;
                    width: 11px;
                    height: 11px;
                    border: 1px solid #000;
                    color: #000;
                    border-radius: 50%;
                    background-color: #fff;
                    text-align: center;
                    line-height: 10px;
                    font-size: 10px;
                  }
                }
              }
            }
          }

          .section {
            margin-top: 30px;
            p {
              font-weight: 400;
              margin-bottom: 10px;
            }
          }
          .section-title {
            font-size: 16px;
            margin-bottom: 10px;
          }
          .content {
            font-size: 14px;
            line-height: 1.6;
            font-weight: normal;
          }
        }
      `}</style>
    </div>
  );
};

export default TermsGeneral;
